import { template as template_06602562b1ae44a0824427c44f88cade } from "@ember/template-compiler";
const WelcomeHeader = template_06602562b1ae44a0824427c44f88cade(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
