import { template as template_4aba429f41714b6a95a8045c8b1e061a } from "@ember/template-compiler";
const FKLabel = template_4aba429f41714b6a95a8045c8b1e061a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
